// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-engagement-js": () => import("./../src/pages/engagement.js" /* webpackChunkName: "component---src-pages-engagement-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-registry-js": () => import("./../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-wedding-js": () => import("./../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */)
}

