const gray = {
  '050': '#f8f8f8',
  '100': '#f5f5f5',
  '200': '#eeeeee',
  '300': '#e0e0e0',
  '400': '#bdbdbd',
  '500': '#9e9e9e',
  '600': '#757575',
  '700': '#616161',
  '800': '#424242',
  '900': '#212121',
}

// example base theme from @theme-ui/presets
const buttonBase = {
  py: 1,
  px: 3,
  fontSize: 2,
  fontWeight: '300',
  borderWidth: 1,
  borderStyle: 'solid',
  transition: 'all 150ms ease-in-out',
  letterSpacing: '0.05em',
}

const mdxStyles = {
  h1: {
    variant: 'text.heading',
    fontSize: 5,
    mb: 2,
  },
  h2: {
    variant: 'text.heading',
    fontSize: 4,
    mb: 2,
  },
  p: {
    fontSize: 2,
    fontWeight: 300,
    mb: 2,
    lineHeight: 1.5,
  },
}

export default {
  styles: mdxStyles,
  colors: {
    primary: gray['900'],
    secondary: gray['100'],
    background: '#ffffff',
    text: gray['700'],
    gray,
  },
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'Lora, Georgia, serif',
  },
  fontWeights: {
    body: 300,
    heading: 300,
    bold: 700,
  },
  buttons: {
    primary: {
      ...buttonBase,
      color: 'white',
      bg: 'primary',
      borderColor: 'transparent',
    },
    secondary: {
      ...buttonBase,
      color: 'primary',
      bg: 'white',
      borderColor: 'primary',
    },
    text: {
      color: 'primary',
      textDecoration: 'none',
      ':hover, :focus': {
        '> span::after': {
          transform: 'scaleX(1)',
        },
      },
      '> span': {
        position: 'relative',
      },
      '> span::before': {
        content: '""',
        position: 'absolute',
        right: 0,
        bottom: '-0.1em',
        left: 0,
        height: '1px',
        backgroundColor: 'gray.300',
      },
      '> span::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        bottom: '-0.1em',
        left: 0,
        height: '1px',
        backgroundColor: 'primary',
        transformOrigin: 'center left',
        transform: 'scaleX(0)',
        transitionProperty: 'transform',
        transitionTimingFunction: 'ease-in-out',
        transitionDuration: '150ms',
        transitionDelay: 'calc(var(--index) * 0.075s)',
      },
    },
  },
  text: {
    heading: {
      color: 'primary',
      fontFamily: 'heading',
      fontWeight: 'heading',
    },
    smallHeading: {
      color: 'primary',
      fontFamily: 'body',
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
    },
    body: {
      fontSize: 2,
      fontWeight: 300,
      mb: 2,
      lineHeight: 1.5,
    },
  },
}
